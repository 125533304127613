/* eslint-disable no-unused-vars */
import React from "react";

import { Table } from "antd";

//import 'antd/lib/table/style/index.css';

const DataTable = (params) => {
  const { columns, data } = params

  return (
    <>
      <div>
        <div
          style={{
            marginBottom: 20,
          }}
        ></div>
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: true, y: true }}
        />
      </div>
    </>
  );
};

export default DataTable;
