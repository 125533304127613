/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';

import { createNotification } from 'components/react-notification';
import TextField from 'components/textfield';
import Button from 'components/button';

//import { setUserReduxLogin } from "redux/auth/auth-actions";
//import { userService } from "services/users";

import loginLogo from 'assets/icons/auth.jpg';
import mainLogo from 'assets/icons/app-logo.png';
import crossIcon from 'assets/icons/cross-gray.svg';
import eyeOpen from 'assets/icons/Hide.svg';
import eyeClose from 'assets/icons/Show.svg';

import style from './login.module.scss';
import { authService } from 'services/auth-services';
import { useDispatch } from 'react-redux';
import { setAuthState } from 'redux/features/appStateSlice';

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { email, userId } = data;

    try {
      const result = await authService.forgotPassword({
        metaData: {
          reqType: 'OFCR_ADD',
          reqtId: null,
          reqTransId: '1E23118',
          clientId: 'LT',
        },
        data: {
          username: userId,
          email: email,
        },
      });
      console.log('result', result?.data?.data);
      if (result?.data?.data) {
        dispatch(setAuthState(result?.data?.data));
        navigate(`/otp-verification/${userId}/change`);
      }
    } catch (err) {
      console.log('err', err);

      createNotification(
        'error',
        'Login Error',
        'Some thing happening wrong',
        3500
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.rightInnerDiv}>
        <img src={loginLogo} alt="" style={{ width: '100%', height: '100%' }} />
        <div
          style={{
            position: 'absolute',
            display: 'block',
            top: '20%',
            left: '5%',
          }}
        >
          <p style={{ color: '#ffff' }}>Welcome to,</p>
          <h1 style={{ color: '#ffff' }}>
            E - Directorate of Intelligence & Security
          </h1>
          <h3 style={{ color: '#ffff' }}>
            where security meets intelligence..
          </h3>
        </div>
      </div>

      <div className={style.mainDiv}>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <img src={mainLogo} alt="" className={style.logo} />
        </div>
        <h2 style={{ marginBottom: -5 }}>Forgot your password?</h2>
        <p
          style={{
            textDecoration: 'none',
            color: '#7f7f7f',
            fontSize: 14,
            marginTop: -30,
          }}
        >
          Enter your User ID & Email address associated with your account and
          we’ll send you a unique code to reset your password.
        </p>
        <TextField
          label="User ID"
          className={style.field}
          name="userId"
          register={register}
          // placeholder="User ID"
          errorMessage={errors?.userId?.message}
          //  icon={crossIcon}
          onClick={() => reset({ userId: '' })}
          // iconClass={style.crossIcon}
        />
        <TextField
          label="Email"
          className={style.field}
          name="email"
          register={register}
          //  placeholder="Email ID"
          errorMessage={errors?.email?.message}
          //  icon={crossIcon}
          onClick={() => reset({ email: '' })}
          // iconClass={style.crossIcon}
        />
        {/* <div className={style.forgotPassword}>
          <p
            style={{
              textDecoration: "none",
              color: "#7f7f7f",
            }}
          >
            Not able to Log in? Try{" "}
            <Link
              style={{
                textDecoration: "none",
                color: "#2F80ED",
                fontWeight: "bold",
              }}
              to="/otp-verification"
            >
              Forgot password?
            </Link>
          </p>
        </div> */}
        <div className={style.buttonDiv}>
          <Button
            type={'submit'}
            text="Send Code"
            btnClass={style.loginButton}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    </form>
  );
}

export default ForgotPassword;

const schema = yup
  .object({
    userId: yup.string().required('User Id is required '),
    email: yup
      .string()
      .required('Email is required ')
      .email('Please enter valid email'),
  })
  .required();
