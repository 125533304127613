import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { updateUser } from "services/users";
import { setLoggedInUser } from 'redux/features/appStateSlice';

const BinanceForm = ({handle}) => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.appState.loggedInUser)
  const [formData, setFormData] = useState({
    client_id: loggedInUser.client_id,
    client_secret: loggedInUser.client_secret,
    email: loggedInUser.email,
    full_name: loggedInUser.full_name,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await updateUser(loggedInUser.id, formData)
    handle()
    dispatch(setLoggedInUser(result.data))
  };

  return (
    <>
      <span className="text-bold text-2xl">Please Follow below steps to configure your Binance Account with our Bot.</span>

      <div>
        <div className="mt-[1rem] text-3xl text-bold">
          <span>Step 1: </span> Go to your Binance account and open the "API Management" page.
          <img src={"https://usdtheist.s3.ap-south-1.amazonaws.com/step-1.png"} alt="Binance-API-Management" border="0" width={'1300px'} />
        </div>
        <div className="mt-[1rem] text-3xl text-bold">
          <span>Step 2: </span> A dialog box will appear. Click on 'Next'. Write your API KEY Name (Your own choice) and click Next. Then complete the security verifications.
          <img src={"https://usdtheist.s3.ap-south-1.amazonaws.com/step-2.png"} alt="Binance-API-Management" border="0" width={'1300px'} />
        </div>
        <div className="mt-[1rem] text-3xl text-bold">
          <span>Step 3: </span> Your key is generated. Copy 'Api Key' and 'Secret Key' and provide us in the below fields.
          <img src={"https://usdtheist.s3.ap-south-1.amazonaws.com/step-3.png"} alt="Binance-API-Management" border="0" width={'1300px'} />
        </div>
        <div className="mt-[1rem] text-3xl">
          <span>Step 4: </span> Your key is generated. Now follow these steps
          <ol className="text-xl">
            <li>Click on 'Restrict access to trusted IPs only (Recommended)'</li>
            <li>Enter '<span className="bg-green-300">{process.env.REACT_APP_SERVER_IP}</span>'</li>
            <li>Click 'Confirm'.</li>
            <li>Enable 'Enable Spot and Margin Trading'</li>
            <li>Click on 'Save'</li>
            <li>Complete the security verifications</li>
          </ol>
          <img src={"https://usdtheist.s3.ap-south-1.amazonaws.com/step-5.png"} alt="Binance-API-Management" border="0" width={'1300px'} />
        </div>
      </div>

      <form onSubmit={handleSubmit} className="border m-[2rem] flex justify-center flex-col items-center p-[2rem]">
        <div>
          <label>API Key:</label>
          <input
            type="text"
            name="client_id"
            value={formData.client_id}
            onChange={handleChange}
            placeholder="Enter API key"
            className="w-[600px] ml-[2rem] p-[1rem] mb-[2rem]"
            required
          />
        </div>

        <div>
          <label>Secret Key:</label>
          <input
            type="text"
            name="client_secret"
            value={formData.client_secret}
            onChange={handleChange}
            placeholder="Enter Secret Key"
            className="w-[600px] ml-[2rem] p-[1rem] mb-[2rem]"
            required
          />
        </div>
        <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Submit</button>
      </form>
    </>
  );
}

export default BinanceForm;
