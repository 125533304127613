import React from 'react';
import BinanceForm from '../setup/binanceForm';
import { createNotification } from 'components/react-notification';

const Account = () => {
  const handleFormSubmit = () => {
    createNotification(
      "success",
      "Account",
      "Accout has been updated successfully",
      5000
    );
  }
  return (
    <BinanceForm handle={handleFormSubmit}/>
  )
}

export default Account;
