import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Toolbar, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import { useSelector } from 'react-redux';
import { type appState } from "redux/features/appStateSlice";
// import Topbar from "../common/Topbar";

const MainLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const loggedInUser = useSelector((state: appState) => state.appState.loggedInUser);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* <Topbar /> */}
      <Box
        component="nav"
        sx={{
          width: sizeConfigs.sidebar.width,
          flexShrink: 0,
          display: { xs: isSidebarOpen ? "block" : "none", md: "block" },
        }}
      >
        <Sidebar />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${sizeConfigs.sidebar.width})`,
          minHeight: "100vh",
          backgroundColor: colorConfigs.mainBg,
        }}
      >
        {/* <div className="mb-[1rem]">Welcome <span className="text-2xl">{loggedInUser.full_name}</span></div> */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
