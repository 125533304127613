import { API_URLS } from './url-paths';
import axios from 'utils/axios';

export const getAllUserStratiges = async (user_id, coin_id) => {
  let url = `${API_URLS.GET_ALL_USER_STRATAGIES}?user_id=${user_id}&coin_id=${coin_id}`

  return axios.get(url);
};

export const getAllCoins = async () => {
  let url = `${API_URLS.GET_ALL_COINS}`

  return axios.get(url);
}

export const getStrategy = async (id) => {
  let url = `${API_URLS.GET_ALL_USER_STRATAGIES}/${id}`

  return axios.get(url)
}

export const getStrategies = async(coin_id) => {
  let url = `${API_URLS.GET_ALL_STRATEGIES}?coin_id=${coin_id}`

  return axios.get(url)
}

export const updateStrategy = async(id, params) => {
  let url = `${API_URLS.GET_ALL_STRATEGIES}/${id}/`;

  return axios.put(url, params);
}

export const updateBulkStrategies = async(params) => {
  let url = `${API_URLS.GET_ALL_STRATEGIES}/bulk/`;

  return axios.post(url, params)
}

export const updateUserStrategy = async (id, params) => {
  let url = `${API_URLS.GET_ALL_USER_STRATAGIES}/${id}/`;

  return axios.put(url, params);
}
