import React from 'react';
import Button from 'components/button';

const Request = (params) => {
  const { userRequest, index, onUpdateHandler } = params

  return (
    <div className={'flex flex-col bg-white max-w-sm p-3 rounded overflow-hidden shadow-lg'} id={index}>
      <div className='divide-y divide-black-300'>
        <div className='flex flex-row justify-between items-center'>
          <div className="text-3xl py-2">{userRequest.full_name}</div>
          <div className='text-red-500'>In Active</div>
        </div>
        <div className='flex p-2 justify-between'>
          <div>
            <img src={userRequest.payment_receipt_url} width={"300px"}/>
          </div>
        </div>
      </div>
      <div className='flex justify-between'>
        <Button
          text="Approve"
          handleClick={() => onUpdateHandler(userRequest.id)}
        />
      </div>
    </div>
  )
}

export default Request;
