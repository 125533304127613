import React, { useState } from "react";
import BinanceForm from "./binanceForm";
import { useSelector } from "react-redux";
import ApprovalForm from "./approvalForm";

export const Setup = () => {
  const loggedInUser = useSelector((state) => state.appState.loggedInUser)
  const [paymentRequested, setPaymentRequested] = useState(loggedInUser.payment_receipt_url !== null)
  const [binanceFormFilled, setBinanceFormFilled] = useState(loggedInUser.client_id !== null)

  const handleFormFill = () => {
    setBinanceFormFilled(true)
  }

  return (
    <>
      <div className="text-3xl h-[3rem]">Welcome to USDTHEIST.</div>
      {
        binanceFormFilled ? (
          paymentRequested ? (
            <div>Request is forwarded to Admin. We're reviewing your request. Once the request is approved, you can use our services</div>
          ) : (
            <ApprovalForm handle={() => setPaymentRequested(true)}/>
          )
        ) : (<BinanceForm handle={handleFormFill} />)
      }
    </>
  )
}

export default Setup;
