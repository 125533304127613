import React from 'react';

const TermsModal = ({showModal, setShowModal}) => {
  return (
    <>
      {showModal && (
        <>
          <div
            className="justify-center w-[100%] items-center flex overflow-none fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-[80%] overflow-n m-auto">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Terms and Conditions
                  </h3>
                  <button
                    className="p-1 ml-auto float-right font-semibold items-center"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-4xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="overflow-y-scroll max-h-[700px] mx-[2rem] mt-[1rem] mb-[2rem]">
                <p>These <strong>terms and conditions</strong> outline the rules and regulations for the smooth use of “USDT HEIST – www.usdtheist.com” Website.</p>
<p><strong>By accessing this website, we assume that you accept these terms and conditions fully.</strong> If you do not accept all of the terms and conditions stated on this page.</p>
<p>Please do not continue to use website of “USDT HEIST –www.usdtheist.com”<br /><br /> 1. <strong>RISK DISCLOSURE</strong></p>
<p>www.usdtheist.com is not to be considered financial advice. Use at your own risk.</p>
<p>www.usdtheist.com will not accept any liability for loss or damage as a result of reliance on the information contained within this website including software, data, buy/sell signals. Please be fully informed regarding the risks and costs associated with trading the digital markets, it is one of the riskiest investment forms possible.<br /><br /><strong><u>Cryptocurrency trading involves risk</u></strong>, and is not suitable for all investors. Before deciding to trade cryptocurrency on exchange or any other crypto trading instrument you should carefully consider your investment objectives, level of experience, and risk appetite.<br />www.usdtheist.com doesn’t bear any responsibility for any trading losses you might incur as a result of using this data or software or website.<br />www.usdtheist.com would like to remind you that its a strategy driven trading bot based on multiple indicators.<br /><br />Any controversial dump /dip /rug pull / hack of any coin / Token / cryptocurrency is the not the responsibility of <a href="http://www.usdtheist.com/"><u>www.usdtheist.com</u></a>. <br />Before trading on any crypto asset in <a href="http://www.usdtheist.com/"><u>www.usdtheist.com</u></a> do your own research about the coin/ token or cryptocurrency .<br />It is AI crypto trading bot totally based on the technical analysis.<br />Any bad news related to any coin/token which is integrated in <a href="http://www.usdtheist.com/"><u>www.usdtheist.com</u></a> for trading may affect the bot results.<br /><br />Therefore <a href="http://www.usdtheist.com/"><u>www.usdtheist.com</u></a> doesn’t bear any responsibility for any trading losses you might incur as a result of using this data or software or website.</p>
<p> </p>
<p><strong>2.</strong><strong>RESTRICTIONS</strong></p>
<p>You are specifically restricted from all of the following</p>
<ul type="disc">
<li>publishing any Website material in any other media;</li>
<li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
<li>publicly performing and/or showing any Website material;</li>
<li>using this Website in any way that is or may be damaging to this Website;</li>
<li>using this Website in any way that impacts user access to this Website;</li>
<li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
<li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</li>
<li>using this Website to engage in any advertising or marketing.</li>
</ul>
<p>Certain areas of this Website are restricted from being access by you and may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.</p>
<p> </p>
<p> </p>
<p><strong>3.</strong><strong> CHANGES TO OR SERVICES</strong></p>
<p><strong>We reserve the right at any time to:</strong></p>
<ul type="disc">
<li><strong>Change the terms and conditions </strong></li>
<li><strong>Change the Site, including eliminating or discontinuing any content or feature of the Site; or</strong></li>
<li><strong>Impose fees, charges or other conditions for use of the Site or parts thereof (with reasonable notice).</strong></li>
</ul>
<p><strong>You agree to be bound by future revisions of this. It is your responsibility to visit this Term of Use page, which is linked at the bottom of Site, periodically for the most current terms and conditions. We may impose limits on certain features and services or restrict your access to parts or all of a Service without notice or liability.</strong></p>
<p><strong>We reserve the right to withdraw or amend the Services, any material we provide on the Services, in our sole discretion without notice. We are not liable if for any reason all or part of the Services is unavailable at any time or for any period. From time to time, we may restrict access to some or all parts of the Services to users, including registered and paid users.</strong></p>
<p><strong>UsdtHeist may modify the Site at any time without prior notice, and you must accept those modifications if you continue to use the Site. You should check the Site frequently to see recent changes.</strong></p>
<p><strong>We may also revise and update this TOU from time to time at our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Services thereafter. By continuing to use of any Service following the posting of a revised, you acknowledge the changes and agree to accept the revised terms and conditions.</strong></p>
<p> </p>
<p><br /><strong>4.</strong><strong>DISCLAIMER</strong><br /><br /><a href="http://www.usdtheist.com/"><u>www.usdtheist.com</u></a><strong>is an independent crypto trading bot. You understand that no content published on our Site or Services constitutes a recommendation that any particular transaction or investment strategy is suitable for any specific person. None of the authors, information providers, or their affiliates is advising personally concerning the nature, potential value or suitability of any particular transaction, investment strategy, or other matter.</strong></p>
<p><strong>To the extent any of the content published on the Site or through our Services may be deemed to be investment advice or recommendations for a financial transaction, such information is impersonal and not tailored to the investment needs of any specific person is not a registered securities broker or dealer. We strongly recommend that you perform your own independent research and/or speak with a qualified investment professional before making any financial decisions.</strong></p>
<p><strong>You understand that investing and trading in cryptocurrency is subject to a number of risks, and that discussions on Site and through our Services will not contain a list or description of relevant risk factors. All content on the Site and Services is presented only as of the date published or indicated, and may be superseded by subsequent market events or for other reasons. In addition, you are responsible for setting the cache settings on your browser to ensure you are receiving the most recent content.</strong></p>
<p><strong>No content published on this Site or Services is intended to provide tax, legal, insurance or investment advice, and nothing should be construed as an offer to sell, a solicitation of an offer to buy, or a recommendation for any security or cryptocurrency by </strong><a href="http://www.usdtheist.com/"><u>www.usdtheist.com</u></a><strong> or any third party. We make no implied or expressed guarantee to the accuracy or completeness of the content, opinions, or ideas expressed in our Site and Services.</strong></p>
<p><strong>You alone are solely responsible for determining whether any investment, strategy, or any other product or service, is appropriate or suitable for you based on your investment objectives and personal and financial situation. You should consult an attorney or tax professional regarding your specific legal or tax situation.</strong></p>
<p><br /><strong>5. </strong><strong>PROPRIETARY RIGHTS AND COPYRIGHT PROTECTION</strong></p>
<p><strong>All of the information, content, and services transmitted through, or used in connection with the Site and Services, including, for example, trading strategy, reviews, text, photographs, images, illustrations, html, source and object code, software, Data (as defined below), etc. (collectively, the “Content”), as well as its trade dress, layout, presentation, selection and arrangement, is owned by usdtheist. You may not use such material except as provided.</strong></p>
<p><strong>You may use the Services, including any Content, online and solely for your personal, non-commercial use, and you may download or print a single copy of any portion of the Content for your personal, non-commercial use, you do not remove any trademark, copyright or other notice contained in such Content. No other use is permitted.</strong></p>
<p><strong>You may not republish the Content on any Internet, Intranet or Extranet site or incorporate the Content in any database, compilation, archive or cache. You may not distribute any of the Content to others, whether or not for payment or other consideration, and, unless explicitly permitted on the Services, you may not modify, copy, frame, reproduce, sell, publish, transmit, display, download, share or otherwise use any portion of the Content without our prior written consent.</strong></p>
<p><strong>For information on requests to use of the Content for any purpose other than as permitted in this paragraph, please contact us directly. Any use of the Services not expressly permitted by this Agreement is a breach of this Agreement and may violate copyright, trademark and other laws.</strong></p>
<p><strong>You acknowledge that the Services contain data and other information including the written word, instruments, charts, graphs, algorithms, ratings, rankings, products, vehicles or devices, or gathered by UsdtHeist from other sources (“Data”) that is highly proprietary in nature and that unauthorized copying, transfer or use may cause</strong><strong>UsdtHeist</strong><strong>or its affiliates, agents, information providers, and licensors irreparable injury that cannot be adequately compensated for by means of monetary damages. You agree that any breach of the Agreement may be enforced by</strong><strong>UsdtHeist</strong><strong>by means of equitable relief (including, but not limited to, injunctive relief) in addition to any other available rights and remedies.</strong></p>
<p><strong>You may not frame or utilize framing techniques that involve any Content, trademark, logo, copyrighted material or other proprietary information (including images, text, page layout, or form) of any portion of the Services without our express prior written consent.</strong></p>
<p><strong>In addition, you agree not to decompile, reverse engineer or disassemble any software or other products or processes accessible through the Services, not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Services, not to take any action that imposes an unreasonable or disproportionately large load on our infrastructure, and not to use any data mining, robot, spider, crawler, cancelbots, Trojan horse, or any data gathering or extraction method or manual process to facilitate the misuse of the Services. Please also see</strong><strong>Section 6 (“User Conduct”).</strong></p>
<p><strong>We may, at any time, change or discontinue any aspect or feature of a Service, including Content. We may update the Content but Content is not necessarily complete or up-to-date. Any of the material on the Services may be out of date at any given time, and we are under no obligation to update such material.</strong><br /><br /><strong>6. USER CONDUCT</strong></p>
<p><strong>The</strong><strong>Site and Services</strong><strong>are intended for your personal, non-commercial use in accordance with these. You may not use, copy, display, sell, license, de-compile, republish, upload, post, transmit, distribute, create derivative works or otherwise exploit Content from the Site or Services to online bulletin boards, message boards, newsgroups, chat rooms, or in any other manner, without our prior written permission. Illegal and/or unauthorized use, duplication, redistribution, or disclosure of the Site and Services is a violation of this TOU and we reserve the right to take legal action for any such violation to the fullest extent permitted by law.</strong></p>
<p><strong>By using our Site and Services, you agree not to:</strong></p>
<ul type="disc">
<li><strong>Violate any Applicable Law;</strong></li>
<li><strong>Restrict or inhibit any other visitors from using the Site, including, without limitation, by means of “hacking” or defacing any portion of the Site;</strong></li>
<li><strong>Express or imply that any statements you make are endorsed by us, without our prior written consent;</strong></li>
<li><strong>Copy any of the material on the Services or Sites or to use for any other unauthorized purpose without our prior written consent;</strong></li>
<li><strong>Share your account or access to Services without our prior written consent;</strong></li>
<li><strong>Modify, adapt, sublicense, translate, sell, reverse engineer, decompile or disassemble any portion of the Site;</strong></li>
<li><strong>“Frame” or “mirror” any part of the Site without our prior written authorization;</strong></li>
<li><strong>Use any robot, spider, site search/retrieval application, or other manual or automatic device or process to download, retrieve, index, “data mine”, “scrape”, “harvest” or in any way reproduce or circumvent the navigational structure or presentation of the Site or its contents;</strong></li>
<li><strong>Harvest or collect information about visitors to the Site and Services without their express consent;</strong></li>
<li><strong>Send unsolicited or unauthorized advertisements, spam, chain letters, etc. to other users of the Site and Services;</strong></li>
<li><strong>Introduce any viruses, Trojan horses, worms, logic bombs or other material which is malicious or technologically harmful or otherwise attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Services, the server on which the Services are stored, or any server, computer or database connected to the Services; and</strong></li>
<li><strong>Transmit any Content, which contains software viruses, or other harmful computer code, files or programs.</strong></li>
</ul>
<p><strong>In order to access some of the services of the Site and Services, you will have to create an account.</strong><strong>By creating this account, you agree to the following:</strong></p>
<ul type="disc">
<li><strong>You may only maintain a single account;</strong></li>
<li><strong>You may never share your account username or password or knowingly provide or authorize access to your account;</strong></li>
<li><strong>You may never use another user’s account without permission;</strong></li>
<li><strong>When creating your account, you must provide accurate and complete information;</strong></li>
<li><strong>You are solely responsible for the activity that occurs on your account, and you must keep your account password secure;</strong></li>
<li><strong>You must notify us immediately of any breach of security or unauthorized use of your account;</strong></li>
<li><strong>You will be liable for any use made of your account or password and the losses of UsdtHeist or others due to such unauthorized use. We will not be liable for your losses caused by any unauthorized use of your account.</strong></li>
<li><strong>You will not share signal data, market analysis or other valuable information from our platform</strong></li>
</ul>
<p> </p>
<p><strong>7. </strong><strong>TERMINATION</strong></p>
<p><strong>You understand and agree that</strong><strong>UsdtHeist</strong><strong>may under certain circumstances and without prior notice to you terminate your access to and use of the Site and Services including your subscription. Cause for such termination shall include, but not be limited to, </strong><br /><strong>(i)</strong><strong>breaches or violations</strong><strong>or other agreements or guidelines</strong><br /><strong> (ii)</strong><strong>requests by law enforcement</strong><strong>or other government or regulatory authority </strong><br /><strong> (iii)</strong><strong>repeat violators copyrights</strong><strong>or other intellectual property.</strong></p>
<p><strong>We also reserve the right to limit the availability of the Site and Services to any person, geographic area, or jurisdiction we so desire, at any time and in our sole discretion, and to limit the quantities of any such service or product that we provide.</strong></p>
<p> </p>
<p> </p>
<p> </p>
<p><strong>8.</strong><strong>CREDIT &amp; CONTACT INFORMATION</strong></p>
<p><strong>We (</strong><a href="http://www.usdtheist.com/"><u>www.usdtheist.com</u></a><strong>) are</strong><strong><u>Official Resellers</u></strong><strong>of the products and/or services distributed on this website.</strong></p>
<p>We (<a href="http://www.usdtheist.com/"><u>www.usdtheist.com</u></a>) are <a href="https://gunthy.org/resellers/"><strong><u>Official License Resellers</u></strong></a> for “<strong>usdtheist</strong>” – a software created by <a href="https://gunthy.org/"><strong><u>AH Ltd</u></strong></a> </p>
<p>If you have any queries regarding any of our terms, please <a href="https://thecryptobot.com/contact/"><u>contact us</u></a> @usdtheist@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  )
}

export default TermsModal;
