/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';

import { createNotification } from 'components/react-notification';
import TextField from 'components/textfield';
import Button from 'components/button';

//import { setUserReduxLogin } from "redux/auth/auth-actions";
//import { userService } from "services/users";

import loginLogo from 'assets/icons/auth.jpg';
import mainLogo from 'assets/icons/app-logo.png';
import crossIcon from 'assets/icons/cross-gray.svg';
import eyeOpen from 'assets/icons/Hide.svg';
import eyeClose from 'assets/icons/Show.svg';

import style from './login.module.scss';
import { authService } from 'services/auth-services';
import { useDispatch } from 'react-redux';
import { setAuthState, setOtpVerified } from 'redux/features/appStateSlice';

function SuccessScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div>
      <div className={style.rightInnerDiv}>
        <img src={loginLogo} alt="" style={{ width: '100%', height: '100%' }} />
        <div
          style={{
            position: 'absolute',
            display: 'block',
            top: '20%',
            left: '5%',
          }}
        >
          <p style={{ color: '#ffff' }}>Welcome to,</p>
          <h1 style={{ color: '#ffff' }}>
            E - Directorate of Intelligence & Security
          </h1>
          <h3 style={{ color: '#ffff' }}>
            where security meets intelligence..
          </h3>
        </div>
      </div>

      <div className={style.mainDiv}>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <img src={mainLogo} alt="" className={style.logo} />
        </div>
        <h2 style={{ marginBottom: -5 }}>Congratulations</h2>
        <p
          style={{
            textDecoration: 'none',
            color: '#7f7f7f',
            fontSize: 14,
            marginTop: -30,
          }}
        >
          Your password has been updated successfully, ensuring enhanced
          security for your account.
          <br />
          <br />
          Please login again to continue
        </p>

        <div className={style.buttonDiv}>
          <Button
            text="Back To Login"
            btnClass={style.loginButton}
            handleClick={() => {
              navigate('/login');
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SuccessScreen;

const schema = yup
  .object({
    userId: yup.string().required('User Id is required '),
    email: yup
      .string()
      .required('Email is required ')
      .email('Please enter valid email'),
  })
  .required();
