import React, { useEffect, useState } from 'react';
import Request from './request';
import { getAllUsers } from'services/users';
import { useSelector } from "react-redux";
import { updateUser } from "services/users";

const AdminRequests = () => {
  const [requests, setRequests] = useState([])
  const fetchUserRequests = async () => {
    const result = await getAllUsers(false)

    setRequests(result.data)
  }

  const onUpdateHandler = async (request) => {
    const formData = {
      full_name: request.full_name,
      active: true,
      approved_at: new Date().toISOString()
    }

    const result = await updateUser(request.id, formData)

    if (result.status === 200) {
      fetchUserRequests()
    } else {
      console.error('Error updating user:', result.error)
    }
  }

  useEffect(() => {
    fetchUserRequests()
  }, [])

  return (
    <>
      <div className='text-3xl'>
        Pending Requests
      </div>
      <div className='flex gap-[2rem] flex-wrap'>
        {
          requests.length > 0 ? requests.map((request, index) => {
            return (
              <Request
                userRequest={request}
                key={index}
                onUpdateHandler={() => onUpdateHandler(request)}
              />
            )
          }) : <div className='mt-[3rem]'>No Pending Requests found</div>
        }
      </div>
    </>
  );
}

export default AdminRequests;
