import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Strategy from './strategy';
import { getAllCoins, getAllUserStratiges } from 'services/strategies';
import { useSelector } from 'react-redux';
import Select from 'react-select'
import Loading from "components/loading";
import StrategyModal from './strategyModal';

const Strategies = () => {
  const loggedInUser = useSelector((state) => state.appState.loggedInUser);
  const [strategies, setStrategies] = useState([]);
  const [coins, setCoins] = useState([]);
  const [coin, setCoin] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [strategyId, setStrategyId] = useState('');

  const getAllStrategies = async (coin_id) => {
    setLoading(true);
    const result = await getAllUserStratiges(loggedInUser.id, coin_id);
    setLoading(false);
    setStrategies(result.data);
  }

  const getCoins = async () => {
    const result = await getAllCoins();

    const resultArray = result.data.map((elem) => ({
      label: elem.name.toUpperCase(),
      value: elem.id
    }));

    setCoins(resultArray);
  }

  const handleCoinSelect = async (element) => {
    setCoin(element)

    getAllStrategies(element.value);
  }

  const handleStrategySelect = (strategy_id) => {
    setShowModal(true);
    setStrategyId(strategy_id)
  }

  useEffect(() => {
    getCoins();
  }, [])

  return (
    <div className='flex flex-col gap-4'>
      {
        showModal && <StrategyModal showModal={showModal} setShowModal={setShowModal} strategies={strategies} strategy_id={strategyId} />
      }
      <div>
        <Select 
          options={coins}
          value={coin}
          onChange={handleCoinSelect}
        />
      </div>
      {
        loading ? (<Loading loaderClass="w-[5rem] h-[5rem]" />) : (
          <div className='grid md:grid-cols-3 grid-wrap gap-9 md:ml-[30px]'>
            {
              strategies.length > 0 ? strategies.map((user_strategy, index) => (
                <Strategy
                  strategies={strategies}
                  setStrategies={setStrategies}
                  user_strategy={user_strategy}
                  key={index}
                  coin={coin}
                  getStrategies={() => getAllStrategies(coin.value)}
                  onClickHandle={() => handleStrategySelect(user_strategy.id)}/>
              )) : (
                <div className='text-red-700 text-xl'>
                  Please select Coin to see the strategies
                </div>
              )
            }
          </div>
        )
      }
    </div>
  );
}

export default Strategies;
