import React, { useState, useEffect } from 'react';
import { getAllUsers } from'services/users';
import UserTable from './UserTable';

const AdminUsers = ()  => {
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    const result = await getAllUsers();

    setUsers(result.data)
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <UserTable users={users} setUsers={setUsers} />
  )
}

export default AdminUsers;
