import React, {useEffect, useState} from 'react';
import TradeTable from './tradeTable';
import { useSelector } from "react-redux";
import { getAllTrades } from 'services/trades';

const Trades = ({ user_id=null }) => {
  const loggedInUser = useSelector((state) => state.appState.loggedInUser)
  const [trades, setTrades] = useState([])
  const [userId, setUserId] = useState(user_id || loggedInUser.id)

  const getTrades = async () => {
    const result = await getAllTrades(userId)
    setTrades(result.data)
  }

  useEffect(() => {
    if (user_id){
      setUserId(user_id)
      getTrades(userId);
    }
  }, [user_id])

  return (
    <TradeTable trades={trades} user_id={ userId } />
  )
}

export default Trades;
