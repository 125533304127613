import { API_URLS } from './url-paths';
import axios from 'utils/axios';

export const getAllUsers = async (active = null) => {
  let url = `${API_URLS.GET_ALL_USERS}`;

  if (active!== null) {
    url = url + `?active=${active}`;
  }

  return axios.get(url);
}

export const getUser = async () => {
  let url = `${API_URLS.GET_LOGGED_IN_USER}`;

  return axios.get(url);
}

export const findUser = async (id) => {
  let url = `${API_URLS.GET_ALL_USERS}/${id}`;

  return axios.get(url);
}

export const updateUser = async (id, params) => {
  let url = `${API_URLS.GET_ALL_USERS}/${id}/`;

  return axios.put(url, params);
}
