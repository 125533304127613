/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { createNotification } from 'components/react-notification';
import TextField from 'components/textfield';
import Button from 'components/button';

import { setUserReduxLogin } from 'redux/auth/auth-actions';
//import { userService } from "services/users";

import loginLogo from 'assets/icons/auth.jpg';
import mainLogo from 'assets/icons/app-logo.png';
import crossIcon from 'assets/icons/cross-gray.svg';
import eyeOpen from 'assets/icons/Hide.svg';
import eyeClose from 'assets/icons/Show.svg';

import style from './resetPassword.module.scss';
import { authService } from 'services/auth-services';
import { setAuthState, setOtpVerified } from 'redux/features/appStateSlice';

function ResetPassword() {
  const dispatch = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { password, confirmPassword } = data;
    console.log('password', password);
    try {
      const result = await authService.changePassword({
        metaData: {
          reqType: 'OFCR_ADD',
          reqtId: null,
          reqTransId: '1E23118',
          clientId: 'LT',
        },
        data: {
          username: id,
          newPassword: password,
        },
      });
      console.log('result', result);
      if (result?.data?.data) {
        // const status = "login";
        //  dispatch(setAuthState(result?.data?.data));
        // navigate(`/otp-verification/${username}`);
        dispatch(setAuthState(null));
        dispatch(setOtpVerified(false));
        navigate('/success');
      }
    } catch (err) {
      console.log('err', err);

      createNotification(
        'error',
        'Login Error',
        'Some thing happening wrong',
        3500
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.rightInnerDiv}>
        <img src={loginLogo} alt="" style={{ width: '100%', height: '100%' }} />
        <div
          style={{
            position: 'absolute',
            display: 'block',
            top: '20%',
            left: '5%',
          }}
        >
          <p style={{ color: '#ffff' }}>Welcome to,</p>
          <h1 style={{ color: '#ffff' }}>E - Digital Intelligence Service</h1>
          <h3 style={{ color: '#ffff' }}>
            where security meets intelligence..
          </h3>
        </div>
      </div>

      <div className={style.mainDiv}>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <img src={mainLogo} alt="" className={style.logo} />
        </div>
        <h2 style={{ marginBottom: -5 }}>New Credentials</h2>
        <TextField
          label="New Password"
          className={style.field}
          name="password"
          placeholder="Password"
          register={register}
          errorMessage={errors?.password?.message}
          wraperClass={style.textFieldWraper}
          onClick={() => setPasswordVisible(!passwordVisible)}
          icon={passwordVisible ? eyeOpen : eyeClose}
          type={passwordVisible ? 'text' : 'password'}
          iconClass={style.eyeIcon}
        />
        <TextField
          label="Confirm Password"
          className={style.field}
          name="confirmPassword"
          placeholder="confirm Password"
          register={register}
          errorMessage={errors?.confirmPassword?.message}
          wraperClass={style.textFieldWraper}
          onClick={() => setPasswordConfirmVisible(!passwordConfirmVisible)}
          icon={passwordConfirmVisible ? eyeOpen : eyeClose}
          type={passwordConfirmVisible ? 'text' : 'password'}
          iconClass={style.eyeIcon}
        />

        <div className={style.buttonDiv}>
          <Button
            type={'submit'}
            text="Submit"
            btnClass={style.loginButton}
            isLoading={isSubmitting}
          />
        </div>
      </div>
    </form>
  );
}

export default ResetPassword;

const schema = yup.object({
  confirmPassword: yup
    .string()
    .required('Password is required ')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one special character, and one number'
    ),
  password: yup
    .string()
    .required('Password is required ')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one special character, and one number'
    ),
});
