const Admin = "Admin";
const AdminRecommendations = "Recommendations"
const Account = "Account";
const Users = "Users";
const Setup = "Setup";
const Requests = "Pending Requests";
const Plans = "Home Page";
const Strategies = "Strategies";
const Trades = "Trades";
const Objective = "Objectives";
const Tasks = "Tasks";
const Intels = "Intels";
const CommonData = "Common Data";
const UserPreferences = "User Preferences";
const DataGathering = "Data Gathering";
const DataEnrichment = "Data Enrichment";
const DataAnalysis = "Data Analysis";
const Reports = "Reports";
const UserManagement = "User Management";
const Configuration = "Configuration";
const Statistics = "Statistics";
const Performance = "Performance";
const REPOSITORY = "Repository";
const ROUTE_TEXT = {
  AdminRecommendations,
  Admin,
  Account,
  Users,
  Requests,
  Setup,
  Plans,
  Objective,
  Tasks,
  Intels,
  CommonData,
  UserPreferences,
  DataGathering,
  DataEnrichment,
  DataAnalysis,
  Reports,
  UserManagement,
  Configuration,
  Statistics,
  Performance,
  REPOSITORY,
  Strategies,
  Trades,
};

export default ROUTE_TEXT;
