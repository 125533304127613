/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { createNotification } from 'components/react-notification';
import TextField from 'components/textfield';
import Button from 'components/button';

//import { setUserReduxLogin } from "redux/auth/auth-actions";
//import { userService } from "services/users";

import loginLogo from 'assets/icons/auth.jpg';
import mainLogo from 'assets/icons/app-logo.png';
import OtpInput from 'react-otp-input';
import style from './otpVerification.module.scss';
import { authService } from 'services/auth-services';
import { setOtpVerified } from 'redux/features/appStateSlice';

function OtpVerification() {
  const { id, status } = useParams();
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log('status', status);
  const onSubmit = async () => {
    console.log('otp', otp);
    try {
      const result = await authService.validateOtp({
        metaData: {
          reqType: 'OFCR_ADD',
          reqtId: null,
          reqTransId: '1E23118',
          clientId: 'LT',
        },
        data: {
          username: id,
          otp: otp,
        },
      });
      console.log('result', result);
      if (result?.data?.data.message == 'Success') {
        if (status == 'change') {
          navigate(`/reset-password/${id}`);
        } else {
          dispatch(setOtpVerified(true));
        }
      } else if (result?.data?.data.message == 'OTP Verification Failed') {
        createNotification(
          'error',
          'Login Error',
          result?.data?.data.description,
          3500
        );
      }
    } catch (err) {
      console.log('err', err);

      createNotification(
        'error',
        'Login Error',
        'some thing happening wrong',
        3500
      );
    }
  };

  return (
    <div>
      <div className={style.rightInnerDiv}>
        <img src={loginLogo} alt="" style={{ width: '100%', height: '100%' }} />
        <div
          style={{
            position: 'absolute',
            display: 'block',
            top: '20%',
            left: '5%',
          }}
        >
          <p style={{ color: '#ffff' }}>Welcome to,</p>
          <h1 style={{ color: '#ffff' }}>
            E - Directorate of Intelligence & Security
          </h1>
          <h3 style={{ color: '#ffff' }}>
            where security meets intelligence..
          </h3>
        </div>
      </div>

      <div className={style.mainDiv}>
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <img src={mainLogo} alt="" className={style.logo} />
        </div>
        <h2 style={{ marginBottom: -5 }}>OTP Verification</h2>
        <span className={style.lightText} style={{ marginTop: -10 }}>
          An OTP has been sent to your registered Email
        </span>
        {/* <div className={style.emailDiv}>
          <span className={style.lightText}>xxxxxxx@edis.btw.com</span>
          <span className={style.lightText} style={{ color: "#2F80ED" }}>
            Change Email
          </span>
        </div> */}
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            width: '46px',
            marginBottom: '10px',
            height: '55px',
            backgroundColor: '#F4F5FA',
            border: 'none',
            borderRadius: '10px',
          }}
        />
        <div
          style={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}
        >
          <span className={style.lightText} style={{ color: '#484A54' }}>
            Time Remaining: 02:00
          </span>
        </div>
        {/* <div className={style.forgotPassword}>
          <p>
            <Link
              style={{
                textDecoration: "none",
                color: "#7f7f7f",
              }}
              to="/forgot-password"
            >
              Forgot password?
            </Link>
          </p>
        </div> */}

        <div className={style.buttonDiv}>
          <Button
            handleClick={onSubmit}
            disabled={otp.length !== 6 ? true : false}
            text="Verify OTP"
            btnClass={
              otp.length !== 6 ? style.loginButtonDisabled : style.loginButton
            }
            //  isLoading={isSubmitting}
          />
          <Button
            btnClass={`${style.btnClassModalWithoutColor}  `}
            className={style.btnCancelText}
            text="Resend OTP"
            type={'cancel'}
            //  isLoading={submitting}
          />
        </div>
      </div>
    </div>
  );
}

export default OtpVerification;
