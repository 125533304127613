import React, { useState } from "react";
import DataTable from 'components/table';
import UserFormModal from "./userFormModal";
import { useSelector } from 'react-redux';

const UserTable = ({ users, setUsers }) => {
  const loggedInUser = useSelector((state) => state.appState.loggedInUser);
  const [showModal, setShowModal] = useState(false)
  const [userId, setUserId] = useState(null)

  const selectUserHandler = (event) => {
    setUserId(event.target.dataset.user_id)
    setShowModal(true)
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      ellipsis: true,
      render: (text, index) => {
        return <div>{text}</div>
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      render: (text, index) => {
        return <>{text}</>
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text, record) => {
        return <>{text}</>
      }
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (text, record) => {
        return <>{text ? (
          <span className="text-green-500">Active</span>
        ) : (
            <span className="text-red-500">In Active</span>
        )}</>
      }
    },
    {
      title: "Date Joined",
      dataIndex: "date_joined",
      key: "date_joined",
      render: (text, record) => {
        const dateTime = new Date(text).toLocaleTimeString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })

        return <>{dateTime}</>
      }
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        return loggedInUser.id !==record.id && <a onClick={selectUserHandler} data-user_id={record.id}>Edit</a>
      }
    }
  ]

  return (
    <>
      {
        showModal && <UserFormModal setShowModal={setShowModal} user_id={userId} />
      }
      <DataTable columns={columns} data={users} />
    </>
  );
}

export default UserTable;
