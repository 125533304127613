import { API_URLS } from './url-paths';
import axios from 'utils/axios';

export const getAllTrades = async (user_id=null, strategy_id=null) => {
  let url = `${API_URLS.GET_ALL_TRADES}`;

  if (strategy_id !== null && user_id === null) {
    url = url + `?strategy_id=${strategy_id}`;
  } else if (strategy_id === null && user_id !== null) {
    url = url + `?user_id=${user_id}`;
  } else if (strategy_id !== null || user_id !== null) {
    url = url + `?strategy_id=${strategy_id}&user_id=${user_id}`;
  }

  return axios.get(url);
}

export const buyTrade = async (coin_id, strategy_id) => {
  let url = `${API_URLS.GET_ALL_TRADES}/buy`;

  if (coin_id !== null && strategy_id === null) {
    url = url + `?user_strategy_id=${strategy_id}`
  } else if (coin_id === null && strategy_id !== null) {
    url = url + `?coin_id=${coin_id}`
  } else if (coin_id !== null && strategy_id !== null) {
    url = url + `?coin_id=${coin_id}&user_strategy_id=${strategy_id}`
  }

  return axios.get(url)
}

export const sellTrade = async (coin_id, strategy_id) => {
  let url = `${API_URLS.GET_ALL_TRADES}/sell`;

  if (coin_id !== null && strategy_id === null) {
    url = url + `?user_strategy_id=${strategy_id}`
  } else if (coin_id === null && strategy_id !== null) {
    url = url + `?coin_id=${coin_id}`
  } else if (coin_id !== null && strategy_id !== null) {
    url = url + `?coin_id=${coin_id}&user_strategy_id=${strategy_id}`
  }

  return axios.get(url)
}

export const getTradesCount = async (user_id=null, strategy_id=null) => {
  let url = `${API_URLS.GET_ALL_TRADES}/count`;

  if (strategy_id !== null && user_id === null) {
    url = url + `?strategy_id=${strategy_id}`;
  } else if (strategy_id === null && user_id !== null) {
    url = url + `?user_id=${user_id}`;
  } else if (strategy_id !== null || user_id !== null) {
    url = url + `?strategy_id=${strategy_id}&user_id=${user_id}`;
  }

  return axios.get(url);
}
