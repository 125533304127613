import React, { useState, useEffect } from "react"
import { getAllCoins, getStrategies } from "services/strategies";
import Select from 'react-select'
import StrategyList from "./strategyList";

const AdminRecommendations = () => {
  const [coins, setCoins] = useState([]);
  const [coin, setCoin] = useState('');
  const [strategies, setStrategies] = useState([])

  const getCoins = async () => {
    const result = await getAllCoins();

    const resultArray = result.data.map((elem) => ({
      label: elem.name.toUpperCase(),
      value: elem.id
    }));

    setCoins(resultArray);
    setCoin(resultArray[0]);
  }

  const getCoinStrategies = async () => {
    const response = await getStrategies(coin.value)

    setStrategies(response.data)
  }

  const handleCoinSelect = (coin) => {
    setCoin(coin)
  }

  useEffect(() => {
    getCoins();
  }, [])

  useEffect(() => {
    if (coin){
      getCoinStrategies()
    }
  }, [coin])

  return (
    <>
      <div>
        <Select 
          options={coins}
          value={coin}
          onChange={handleCoinSelect}
        />
      </div>

      <div>
        { strategies && <StrategyList strategies={strategies} /> }
      </div>
    </>
  )
}

export default AdminRecommendations
