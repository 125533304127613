import React, { useEffect, useState } from "react";
import { findUser, updateUser } from'services/users';
import TextField from "components/textfield";
// import Select from "react-select"
import Button from 'components/button';
import { Checkbox } from '@mui/material';

const UserFormModal = (props) => {
  const [user, setUser] = useState(null)
  const [fullName, setFullName] = useState('')
  const [role, setRole] = useState()
  const [status, setStatus] = useState()
  const { user_id, setShowModal } = props

  const fetchUser = async () => {
    const response = await findUser(user_id)

    setUser(response.data)
    setFullName(response.data.full_name)
    setRole(response.data.is_staff)
    setStatus(response.data.active)
  }

  const submitHandler = async (event) => {
    event.preventDefault()

    await updateUser(user_id, {
      full_name: fullName,
      is_staff: role,
      active: status
    })

    window.location.reload();
  }

  const handleRoleSelect = (event) => {
    setRole(event.target.checked)
  }

  const handleStatusSelect = (event) => {
    setStatus(event.target.checked)
  }

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <>
      {
        user && (
          <div
            className="justify-center w-[100%] items-center flex overflow-none fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-[30%] overflow-n m-auto">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {user.email}
                  </h3>
                  <button
                    className="p-1 ml-auto float-right font-semibold items-center"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-4xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="overflow-y-auto mx-[2rem] mb-[2rem]">
                  <div>
                    <form onSubmit={submitHandler}>
                      <div className="flex flex-col flex-wrap gap-[1rem]">
                        <div>
                          <TextField 
                            label="Full Name"
                            name="full_name"
                            value={fullName}
                            onChange={(e)=> setFullName(e.target.value)}
                          />
                        </div>
                        <div>
                          <div>
                            <label>Admin</label>
                            <Checkbox
                              type="checkbox"
                              id="setAdmin"
                              checked={role}
                              onChange={handleRoleSelect}
                            />
                          </div>
                          <div>
                            <label>Status</label>
                            <Checkbox
                              type="checkbox"
                              id="setStatus"
                              checked={status}
                              onChange={handleStatusSelect}
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            type={'submit'}
                            text="Save"
                            btnClass=""
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default UserFormModal
