import AdminPageLayout from '../pages/dashboard/AdminPageLayout';
import { RouteType } from './config';

import PlanList from 'pages/planning/plan-list';
import CreatePlan from 'pages/planning/create-plan';

import ROUTE_TEXT from 'utils/route-constants';
import Strategies from 'pages/strategies';
import Trades from 'pages/trades';
import AdminUsers from 'pages/admin/users';
import Setup from 'pages/setup';
import AdminRequests from 'pages/admin/requests';
import Account from 'pages/account';
import AdminTrades from 'pages/admin/trades';
import AdminRecommendations from 'pages/admin/recommendations';

const appRoutes: RouteType[] = [
  {
    path: '/setup',
    element: <Setup />,
    state: 'setup',
    roles: ['User', 'Admin'],
    sidebarProps: {
      displayText: ROUTE_TEXT.Setup,
    },
  },
  {
    path: '/account',
    element: <Account />,
    state: 'account',
    roles: ['User', 'Admin'],
    sidebarProps: {
      displayText: ROUTE_TEXT.Account,
    }
  },
  {
    path: '/strategies',
    element: <Strategies />,
    state: 'strategies',
    roles: ['User', 'Admin'],
    sidebarProps: {
      displayText: ROUTE_TEXT.Strategies,
    },
  },
  {
    path: '/trades',
    element: <Trades />,
    state: 'trades',
    roles: ['User', 'Admin'],
    sidebarProps: {
      displayText: ROUTE_TEXT.Trades,
    },
  },
  {
    path: '/admin',
    element: <AdminPageLayout />,
    state: 'admin',
    roles: ['Admin'],
    sidebarProps: {
      displayText: ROUTE_TEXT.Admin,
    },
    child: [
      {
        path: '/admin/users',
        element: <AdminUsers />,
        state: 'Admin users',
        sidebarProps: {
          displayText: ROUTE_TEXT.Users,
        },
      },
      {
        path: '/admin/requests',
        element: <AdminRequests />,
        state: 'Admin requests',
        sidebarProps: {
          displayText: ROUTE_TEXT.Requests,
        },
      },
      {
        path: '/admin/trades',
        element: <AdminTrades />,
        state: 'Admin trades',
        sidebarProps: {
          displayText: ROUTE_TEXT.Trades,
        },
      },
      {
        path: '/admin/recommendations',
        element: <AdminRecommendations />,
        state: 'Admin Recommendations',
        sidebarProps: {
          displayText: ROUTE_TEXT.AdminRecommendations,
        },
      },
    ]
  },
];

export default appRoutes;
