import Button from 'components/button';
import React, { useEffect, useState } from 'react';
import { updateBulkStrategies } from 'services/strategies';
import { createNotification } from 'components/react-notification';

const StrategyList = (props) => {
  const { strategies } = props;
  const [selectedStrategies, setSelectedStrategies] = useState();

  const clearSelection = () => {
    setSelectedStrategies([])
    console.log('clearing')
  }

  const handleStrategySelect = (strategy_id) => {
    setSelectedStrategies((prevIds) => {
      if (prevIds.includes(strategy_id)) {
        return prevIds.filter((id) => id !== strategy_id);
      } else {
        return [...prevIds, strategy_id]
      }
    })
  }

  const handleSubmitRecommendation = async () => {
    const payload = strategies.map((s) => {
      return {
        id: s.id,
        recommended: selectedStrategies.includes(s.id)
      }
    })

    const response = await updateBulkStrategies(payload)

    createNotification(
      'success',
      'Strategies Recommendation',
      response.data.message,
      3500
    );
  }

  const setDefaultStrategies = () => {
    const defaultRecommended = strategies.filter((s) => s.recommended).map((s) => s.id)

    setSelectedStrategies(defaultRecommended)
  }

  useEffect(() => {
    setDefaultStrategies()
  }, [strategies])

  return (
    <>
      <div className='flex justify-between my-[1rem]'>
        <div>
          <Button type="button" text="Clear All" handleClick={clearSelection} />
        </div>
        <div>
          <Button type="button" text="Recommend" handleClick={handleSubmitRecommendation} />
        </div>
      </div>

      <div className="grid grid-cols-6 gap-4 ml-[1rem]">
        {strategies.map((strategy) => (
          <div className={`${selectedStrategies.includes(strategy.id) && "border border-violet-700 bg-violet-200"} flex flex-col bg-white max-w-sm p-3 rounded overflow-hidden shadow-lg w-fit`} key={strategy.id} onClick={() => handleStrategySelect(strategy.id)}>
            <div className='divide-y divide-black-300'>
              <div className='flex flex-row justify-between items-center'>
                {strategy.name}
              </div>
              <div className='flex p-2 flex-col'>
                <div className='flex p-2 justify-between'>
                  <span>{strategy.rsi_time}-{strategy.rsi_type}-({strategy.buy_at})</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default StrategyList;
